import ArtistsList from '../components/layout/ArtistsList/ArtistsList';
import {push} from '../helpers/seo';

function Artists() {

  push({
    title:       'Artists',
    description: 'Artists booked and managed by Coppr.',
    keywords:    'artists, concerts, events, shows, dates, tickets'
  });

  return (
    <ArtistsList/>
  );
}

export default Artists;
import styles from './MenuButton.module.scss';

function MenuButton(props) {
  return (
    <button className={styles['hamburger'] + (props.active ? ' ' + styles['hamburger-active'] : '')} type="button"
            onClick={props.onClick}>
      <div className={styles['hamburger-box']}>
        <span className={styles['hamburger-inner']}></span>
      </div>
    </button>
  );
}

export 
default
MenuButton;
import Headings from '../components/ui/containers/Headings/Headings';
import {push} from '../helpers/seo';

function NoMatch() {
  push({
    title:       'Ooooops',
    description: 'This page does not exist',
    keywords:    '404, not found, error'
  });

  const ctas = [
    {
      label: 'Go back to the homepage',
      path:  '/'
    }
  ];

  return (
    <div>
      <Headings title="Ooooops!" text="It seems like you're lost..." ctas={ctas}/>
    </div>
  );
}

export default NoMatch;
import styles from './Loader.module.scss';

function Loader(props) {
  return (
    <div className={props.active ? styles['loader'] : styles['loader'] + ' ' + styles['loader-loaded']}>

    </div>
  );
}

export default Loader;
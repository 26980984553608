import styles from './Newsletter.module.scss';

import {useState} from 'react';
import axios from 'axios';

import Button from '../ui/buttons/Button';
import Input from '../ui/inputs/Input';
import {useTranslation} from 'react-i18next';

function Newsletter() {
  const {t} = useTranslation('newsletter');

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email);

    setLoading(true);

    axios
    .post(
      "/backend?action=subscribeNewsletter",
      {
        email: email
      }
    )
    .then((resp) => {
      if (!resp.data.success) {
        alert(t('form.error'));
        console.error(resp.data);
        setLoading(false);
      } else {
        setEmail('');

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
        }, 5000);
      }
    }).catch((err) => {
      alert(t('form.error'));
      console.error(err);
      setLoading(false);
    });
  };

  return (
    <form className={styles.newsletter} onSubmit={handleSubmit}>
      <div className={styles['newsletter__field']}>
        <Input
          type="email"
          name="email"
          placeholder={t('form.email')}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit" className={styles['newsletter__submit']} disabled={loading}>
          {loading ? t('form.loading') : t('form.submit')}
        </Button>
      </div>
      {success && <p className={styles['newsletter__success']}>{t('form.success')}</p>}
    </form>);
}

export default Newsletter;
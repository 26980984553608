import {NavLink} from "react-router-dom";
import logo from '../../../assets/logo.svg';

import styles from './Header.module.scss';
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';

import MenuButton from '../../ui/buttons/MenuButton';

function Header() {
  const {t} = useTranslation('header');
  const links = [
    {
      label: t('navigation.about'),
      path:  '/'
    },
    {
      label: t('navigation.agenda'),
      path:  '/agenda'
    },
    {
      label: t('navigation.artists'),
      path:  '/artists'
    }
  ];


  let [menuActive, setMenuActive] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuActive(!menuActive);
  };

  const menuClick = () => {
    setMenuActive(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!menuActive) {
      document.body.style.overflow = 'auto';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [menuActive]);

  return (
    <header className={styles['header']}>
      <div className={styles['header__logo']}>
        <NavLink to="/">
          <img src={logo} alt="Coppr Art"/>
        </NavLink>
      </div>

      <nav className={styles['header__nav'] + (menuActive ? ' ' + styles['header__nav-active'] : '')}
           onClick={menuClick}>
        <ul className={styles['header__nav-list']}>
          {links.map((link, index) => (
            <li key={index} className={styles['header__nav-item']}>
              <NavLink
                className={({isActive}) =>
                  isActive ? styles['active'] : undefined
                } to={link.path}>{link.label}</NavLink>
            </li>
          ))}
        </ul>

        <div className={styles['header__nav-hamburger']}>
          <MenuButton active={menuActive} onClick={toggleMenu.bind(this)}/>
        </div>
      </nav>
    </header>);
}

export default Header;
import styles from './Input.module.scss';

function Input(props) {
  const classes = [props.className, styles['input__input']];
  if (props.className) {
    delete props.className;
  }

  const props_data = props;

  if (!props_data.type) {
    props_data.type = 'text';
  }

  const Tag = props_data.type === 'textarea' ? 'textarea' : 'input';

  const filled = props_data.value || props_data.defaultValue;
  if (filled) {
    classes.push(styles['input__input--filled']);
  }

  return (
    <div className={styles.input}>
      {props.label && <label className={styles['input__label']}>{props.label}</label>}
      <Tag {...props_data} className={classes.join(' ')}/>
    </div>
  );
}

export default Input;
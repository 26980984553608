import styles from './ArtistsList.module.scss';
import {NavLink} from 'react-router-dom';
import {useSelector} from 'react-redux';

function Card(props) {
  const image_url = props.image.filter(image => image.key === 'thumb')[0].url;

  return (
    <NavLink className={styles.card} to={'/artists/' + props.slug}>
      <img src={image_url} alt={props.name}/>
      <div className={styles['card__infos']}>
        <p className={styles['card__name']}>{props.name}</p>
        {props.services && props.services.length > 0 && (
          <div className={styles['card__services']}>
            {props.services.map((service, index) => {
              return (
                <div className={styles['card__service']}>
                  {service}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </NavLink>
  );
}

function ArtistsList() {
  const content = useSelector(state => state.content.value);

  return (
    <div className={styles.list}>
      {content.artists.map((artist, index) => {
        return (
          <Card key={index} {...artist}/>
        );
      })}
    </div>
  );
}

export default ArtistsList;
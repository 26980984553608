import styles from './Footer.module.scss';
import Text from '../Text/Text';

import Button from '../../ui/buttons/Button';
import Newsletter from '../../forms/Newsletter';

import {useTranslation} from 'react-i18next';

import {useEffect, useState} from 'react';

const emojis = [
  '♥️',
  '🤪',
  '🍺',
  '🎸',
  '🎧',
  '🥁',
  '🫶🏻',
  '☀️'
];

function Footer() {
  const {t} = useTranslation('footer');
  const year = new Date().getFullYear();

  const [emoji, setEmoji] = useState(emojis[Math.floor(Math.random() * emojis.length)]);

  useEffect(() => {
    const interval = setInterval(() => {
      setEmoji(emojis[Math.floor(Math.random() * emojis.length)]);
    }, 2500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.footer}>
      <div className={styles['footer__contact']}>
        <div className={styles['footer__contact_element']}>
          <Text weight="bold" size="large" uppercase={true} className={styles['footer__title']}>
            {t('titles.contact')}
          </Text>
          <div>
            <Button to="/contact">
              {t('ctas.contact')}
            </Button>
          </div>
        </div>

        <div className={styles['footer__contact_element']}>
          <Text weight="bold" size="large" uppercase={true} className={styles['footer__title']}>
            {t('titles.newsletter')}
          </Text>
          <Newsletter/>
        </div>
      </div>
      <footer className={styles['footer__bottom']}>
        <div className={styles['footer-content']}>
          <p>&copy; Coppr {year}</p>
          <p>Made with {emoji} by <a href="https://www.berenger.dev" target="_blank">Berenger.dev</a></p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
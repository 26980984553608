import styles from './Text.module.scss';

function Text(props) {
  const classes = [];
  if (props.className) {
    classes.push(props.className);
  }

  classes.push(styles.text);

  if (props.type) {
    classes.push(styles['text__' + props.type]);
  }

  if (props.size) {
    classes.push(styles['text--' + props.size]);
  }

  if (props.align) {
    classes.push(styles['text--' + props.align]);
  }

  if (props.weight) {
    classes.push(styles['text--' + props.weight]);
  }

  if (props.gutter) {
    classes.push(styles['text--gutter']);
  }

  if (props.bold) {
    classes.push(styles['text--bold']);
  }

  if (props.italic) {
    classes.push(styles['text--italic']);
  }

  if (props.uppercase) {
    classes.push(styles['text--uppercase']);
  }

  const Tag = props.tag ?? 'p';

  return (
    <Tag className={classes.join(' ')}>
      {props.children}
    </Tag>
  );
}

export default Text;
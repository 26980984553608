import {push} from '../helpers/seo';

import {useSelector} from 'react-redux';

import styles from './Global.module.scss';
import Headings from '../components/ui/containers/Headings/Headings';
import Text from '../components/layout/Text/Text';

function Home() {
  const content = useSelector(state => state.content.value);
  const homepage = content.homepage;
  const image = homepage.image.filter(image => image.key === 'banner')[0].url;

  push({
    title:       'About',
    description: 'About Coppr',
    keywords:    'about, coppr, artists, concerts, events, shows, dates, tickets'
  });

  return (
    <div>
      <Headings image={image}/>

      <div className={styles.content}>
        <Text gutter={true} tag="div" align="center">
          <div className={styles.wysiwyg} dangerouslySetInnerHTML={{__html: homepage.content}}/>
        </Text>
      </div>
    </div>
  );
}

export default Home;
import styles from './Button.module.scss';
import {NavLink} from 'react-router-dom';

function Button(props) {
  const Tag = props.to ? NavLink : (props.href ? 'a' : 'button');
  const classes = [styles.button, (props.modifiers ?? []).map(modifier => styles['button-' + modifier])].join(' ');

  const buttonProps = {
    target:    Tag === 'a' ? '_blank' : null,
    href:      props.href,
    to:        props.to,
    className: classes,
    onClick:   props.onClick,
    disabled:  props.disabled
  };

  return (
    <Tag {...buttonProps}  >
      {props.icon && <span className={styles['button__icon']}>{props.icon}</span>}
      <span className={styles['button__label']}>
      {props.children}
      </span>
    </Tag>
  );
}

export default Button;
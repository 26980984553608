import styles from './ActionsBar.module.scss';

import Button from '../../buttons/Button';

function ActionsBar(props) {

  return (
    <div className={styles['actions-bar']}>
      {props.actions.map(action => (
        <Button key={action.label} href={action.url} icon={action.icon} modifiers={['no-label-small']}>
          {action.label}
        </Button>
      ))}
    </div>
  );
}

export default ActionsBar;
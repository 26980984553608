import './App.scss';
import {Outlet, Route, Routes, useLocation} from "react-router-dom";

// Layout
import Header from './components/layout/Header/Header';
import Footer from './components/layout/Footer/Footer';
import Loader from './components/layout/Loader/Loader';

// Pages
import Home from './pages/Home';
import Artists from './pages/Artists';
import Artist from './pages/Artist';
import Agenda from './pages/Agenda';
import Contact from './pages/Contact';
import NoMatch from './pages/NoMatch';

import {useEffect, useState} from "react";

import {useDispatch} from 'react-redux';
import {push} from './stores/reducers/content';

import ScrollTop from './components/ui/buttons/ScrollTop';

function App() {
  const [loading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  const dispatch = useDispatch();

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const dataFetch = async () => {
      const homepage = await (
        await fetch(
          "https://content.copprart.be/nj/items/cp_homepage?fields=content,image.data.thumbnails"
        )
      ).json();

      const artists = await (
        await fetch(
          "https://content.copprart.be/nj/items/cp_artist?fields=name,slug,description,featured_image.data.thumbnails,external_link,spotify_link,youtube_link,apple_music_link,services,service_email&sort=name"
        )
      ).json();

      const agenda = await (
        await fetch(
          "https://content.copprart.be/nj/items/cp_agenda?fields=*,artists.cp_artist_id.slug,artists.cp_artist_id.name&sort=date"
        )
      ).json();

      const getThumbnails = image => image.map(thumbnail => {
        if (thumbnail.key.includes('cp-')) {
          return {
            key:    thumbnail.key.replace('cp-', ''),
            url:    thumbnail.url,
            width:  thumbnail.width,
            height: thumbnail.height
          };
        }
      }).filter(v => v);

      const data = {
        homepage: homepage.data[0] ? {
          content: homepage.data[0].content,
          image:   getThumbnails(homepage.data[0].image.data.thumbnails)
        } : null,
        artists:  artists.data.map(artist => {
          return {
            ...artist,
            website_link: artist.external_link,
            image:        getThumbnails(artist.featured_image.data.thumbnails)
          };
        }),
        agenda:   agenda.data.map(event => {
          return {
            ...event,
            artists: event.artists.map(artist => {
              return {
                name: artist.cp_artist_id.name,
                slug: artist.cp_artist_id.slug
              };
            })
          };
        })
      };

      // Preload images
      data.artists.forEach(artist => {
        artist.image.forEach(image => {
          const img = new Image();
          img.src = image.url;
        });
      });

      dispatch(push(data));
    };

    dataFetch().then(() => {
      setLoading(false);
      setTimeout(() => {
        setShowContent(true);
      }, 500);
    });
  }, []);

  return (
    <div>
      <Loader active={loading}/>

      {loading ? null : (
        <div>
          <Header/>
          <main className="content">
            {showContent ? (
              <Routes>
                <Route path="/" element={<Outlet/>}>
                  <Route index element={<Home/>}/>

                  <Route path="/artists" element={<Artists/>}/>
                  <Route path="/artists/:slug" element={<Artist/>}/>

                  <Route path="/agenda" element={<Agenda/>}/>

                  <Route path="/contact" element={<Contact/>}/>

                  <Route path="*" element={<NoMatch/>}/>
                </Route>
              </Routes>
            ) : null}
          </main>
          <Footer/>
          <ScrollTop/>
        </div>
      )}

    </div>
  );
}

export default App;

import styles from './Headings.module.scss';
import Button from '../../buttons/Button';

function Headings(props) {
  const texts = [];
  if (props.title) {
    texts.push(<h1 key={0} className={styles.h1}>{props.title}</h1>);
  }

  if (props.text) {
    texts.push(<p key={1} className={styles.p}>{props.text}</p>);
  }

  return (
    <div className={styles.headings + (props.image ? ' ' + styles['has-image'] : '')}>
      {props.image &&
       <div className={styles.image + (!texts.length ? ' ' + styles['image--no-text'] : '')}><img src={props.image}
                                                                                                  alt={props.title ??
                                                                                                       props.text}/>
       </div>}

      {texts.map(text => text)}

      {props.ctas ? props.ctas.map((cta, index) => (
        <Button key={index} to={cta.path}>{cta.label}</Button>
      )) : null}
    </div>
  );
}

export default Headings;
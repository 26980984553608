import {createSlice} from '@reduxjs/toolkit';

export const contentSlice = createSlice({
  name:         'content',
  initialState: {
    value: {}
  },
  reducers:     {
    push: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const {push} = contentSlice.actions;

export default contentSlice.reducer;
export function push(data) {
  // Convert data object to meta tags
  const meta = {
    title:       data.title,
    description: data.description,
    keywords:    data.keywords
  };

  // Convert data object to JSON-LD
  const jsonld = {
    '@context':  'http://schema.org',
    '@type':     'WebSite',
    url:         data.url,
    name:        data.title,
    description: data.description,
    keywords:    data.keywords
  };

  // Remove all meta tags from the document
  document.querySelectorAll('meta[data-dyn]').forEach(meta => {
    meta.remove();
  });

  // Push meta tags to document head
  Object.keys(meta).forEach(key => {
    const value = meta[key];

    if (key === 'title' && value) {
      document.title = value + ' — CŌPPR Art';
    } else if (value) {
      const meta = document.createElement('meta');
      meta.setAttribute('data-dyn', true);
      meta.setAttribute('name', key);
      meta.setAttribute('content', value);
      document.head.appendChild(meta);
    }
  });

  // Remove all JSON-LD from the document
  document.querySelectorAll('script[type="application/ld+json"]').forEach(jsonld => {
    jsonld.remove();
  });

  // Push JSON-LD to document head
  const jsonldScript = document.createElement('script');
  jsonldScript.setAttribute('type', 'application/ld+json');
  jsonldScript.innerHTML = JSON.stringify(jsonld);
  document.head.appendChild(jsonldScript);
}
import {useState} from 'react';
import axios from 'axios';

import styles from './Contact.module.scss';

import Button from '../ui/buttons/Button';
import Input from '../ui/inputs/Input';
import Checkbox from '../ui/inputs/Checkbox';
import {useTranslation} from 'react-i18next';


function Contact() {
  const {t} = useTranslation('contact');

  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [optin, setOptin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(full_name, email, message);

    setLoading(true);

    axios
    .post(
      "/backend?action=contact",
      {
        full_name: full_name,
        email:     email,
        phone:     phone,
        message:   message,
        optin:     optin
      },
      {
        baseURL: document.location.protocol + "//" + document.location.host
      }
    )
    .then((resp) => {
      if (!resp.data.success) {
        alert(t('form.error'));
        console.error(resp.data);
        setLoading(false);
      } else {
        setFullName('');
        setEmail('');
        setMessage('');

        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          setLoading(false);
        }, 5000);
      }

    }).catch((err) => {
      alert(t('form.error'));
      console.error(err);
      setLoading(false);
    });
  };

  return (
    <form className={styles['contact-form']} onSubmit={handleSubmit}>
      <div className={styles['contact-form__group']}>
        <Input label={t('form.full_name')} disabled={loading} type="text" id="full_name" name="full_name"
               value={full_name}
               onChange={(e) => setFullName(e.target.value)}/>
      </div>
      <div className={styles['contact-form__cols']}>
        <div className={styles['contact-form__group']}>
          <Input label={t('form.email')} disabled={loading} type="email" id="email" name="email" value={email}
                 onChange={(e) => setEmail(e.target.value)}/>
        </div>
        <div className={styles['contact-form__group']}>
          <Input label={t('form.phone')} disabled={loading} type="tel" id="phone" name="phone" value={phone}
                 onChange={(e) => setPhone(e.target.value)}/>
        </div>
      </div>
      <div className={styles['contact-form__group']}>
        <Input label={t('form.message')} type="textarea" disabled={loading} id="message" name="message" value={message}
               onChange={(e) => setMessage(e.target.value)}></Input>
      </div>
      <div className={styles['contact-form__group']}>
        <Checkbox label={t('form.optin')} type="checkbox" disabled={loading} id="optin" name="optin" value={optin}
                  onChange={(e) => setOptin(e.target.checked)}/>
      </div>
      <div className={styles['contact-form__group']}>
        <Button disabled={loading} type="submit">
          {loading ? t('form.loading') : t('form.submit')}
        </Button>

        {success && <span className={styles['contact-form__success']}>{t('form.success')}</span>}
      </div>
    </form>
  );
}

export default Contact;
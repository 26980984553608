import styles from './Checkbox.module.scss';

function Checkbox(props) {
  const classes = [styles.checkbox];
  if (props.checked) {
    classes.push(styles['checkbox--checked']);
  }

  if (!props.name) {
    props.name = props.id;
  }

  if (!props.id) {
    props.id = props.name;
  }

  if (!props.id) {
    props.id = Math.random().toString(36);
  }

  return (
    <div className={classes.join(' ')} onClick={props.onChange}>
      <input type="checkbox" id={props.id} name={props.name} checked={props.checked} onChange={props.onChange}/>
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  );
}

export default Checkbox;
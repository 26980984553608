import ContactForm from '../components/forms/Contact';
import {useTranslation} from 'react-i18next';

import Headings from '../components/ui/containers/Headings/Headings';
import {push} from '../helpers/seo';
import styles from './Global.module.scss';

function Contact() {
  const {t} = useTranslation('contact');


  push({
    title:       'Contact',
    description: 'Contact',
    keywords:    'contact, copprart, coppr, art'
  });

  return (
    <div>
      <Headings title={t('headings.title')} text={t('headings.message')}/>
      <div className={styles.content}>
        <ContactForm/>
      </div>
    </div>
  );
}

export default Contact;
import styles from './Agenda.module.scss';
import {useSelector} from 'react-redux';
import moment from 'moment/moment';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

import Text from '../Text/Text';

function EventItem(props) {
  const {t} = useTranslation('agenda');
  return (
    <div className={styles.item + (props.passed ? ' ' + styles['item-passed'] : '')}>
      <div className={styles['item__main']}>
        <div className={styles['item__date']}>{props.date.format('ll')}</div>

        {props.showArtists && (
          <div>
            <div className={styles['item__separator']}></div>
            <div className={styles['item__artists']}>
              {props.artists.map((artist, index) => {
                return (
                  <span key={index} className={styles['item__artist']}>
              <NavLink to={'/artists/' + artist.slug}>{artist.name}</NavLink>
            </span>
                );
              })
              }
            </div>
          </div>
        )}
      </div>

      <div className={styles['item__infos']}>
        <p className={styles['item__name']}>
          {props.event_name || props.place_name}
        </p>
        <div className={styles['item__place']}>
          {props.event_name && (<p>{props.place_name}</p>)}
          {props.place_address && (<p>{props.place_address} </p>)}
          {(props.place_city || props.place_zipcode) && (<p>{props.place_city ?? ''} {props.place_zipcode ?? ''}</p>)}
          {false && props.place_country && (<p>{t('countries.' + props.place_country)}</p>)}
        </div>
      </div>

      {!props.passed && (props.tickets_url || props.event_url) && (
        <div className={styles['item__links']}>
          <div className={styles['item__arrow']}>
          </div>

          {props.tickets_url &&
           (<span><a href={props.tickets_url} target="_blank">{t('links.tickets')}</a></span>)}
          {props.event_url &&
           (<span><a href={props.event_url} target="_blank">{t('links.event')}</a></span>)}
        </div>
      )}

    </div>
  );
}

function Agenda(props) {
  const {t} = useTranslation('agenda');
  const content = useSelector(state => state.content.value);

  let events = content.agenda.map(event => {
    return {...event, date: moment(event.date)};
  });

  if (props.artist_slug) {
    events = events.filter(event => {
      return event.artists.some(artist => artist.slug === props.artist_slug);
    });
  }

  const now = moment().startOf('day');
  const passed_events = events.filter(event => {
    return event.date < now;
  }).sort((a, b) => (b.date - a.date));

  const upcoming_events = events.filter(event => {
    return event.date >= now;
  }).sort((a, b) => (a.date - b.date));

  if (props.setAgendaCount) {
    props.setAgendaCount(upcoming_events.length);
  }

  return (
    <div>
      <div className={styles.list}>
        {upcoming_events.map((event, index) => {
          return (
            <EventItem showArtists={!props.artist_slug} key={index} {...event}/>
          );
        })}

        {!props.artist_slug && passed_events.length >
         0 &&
         (<Text className={styles.item} type="separator" bold={true} uppercase={true}>{t('past_events')}</Text>)}

        {!props.artist_slug && passed_events.map((event, index) => {
          return (
            <EventItem showArtists={!props.artist_slug} passed={true} key={index} {...event}/>
          );
        })}

      </div>

    </div>
  );
}

export default Agenda;
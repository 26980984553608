import Headings from '../components/ui/containers/Headings/Headings';
import {useTranslation} from 'react-i18next';

import AgendaBlock from '../components/layout/Agenda/Agenda';
import {push} from '../helpers/seo';

function Agenda() {
  const {t} = useTranslation('agenda');

  push({
    title:       'Agenda',
    description: t('seo.description'),
    keywords:    'agenda, concerts, events, shows, dates, tickets'
  });

  return (
    <div>
      <Headings title={t('headings.title')} text={t('headings.message')}/>
      <AgendaBlock/>
    </div>
  );
}

export default Agenda;
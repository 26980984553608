import styles from './ScrollTop.module.scss';
import {useEffect, useState} from 'react';

function ScrollTop() {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = document.documentElement.scrollTop;
      const viewport_height = window.innerHeight;
      setVisible(scrolled > viewport_height);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top:      0,
      behavior: 'smooth'
    });
  };

  const classes = [styles['scroll-top']];
  if (visible) {
    classes.push(styles['scroll-top--visible']);
  }

  return (
    <div className={classes.join(' ')} onClick={scrollToTop}/>
  );
}

export default ScrollTop;